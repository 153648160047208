import * as React from "react";
import { ReactComponent as IcoArrow } from "../../res/iconos/arrow.svg";
import calcularPx from "./calcularPx";

const CustomLeftArrow = ({ onClick }) => {
  return (
    <div
      style={{
        position: "absolute",
        zIndex: 10,
        left: calcularPx(26),
        cursor: "pointer",
        transform: "rotate(180deg)",
      }}
    >
      <IcoArrow
        className="arrow"
        onClick={() => onClick()}
        width={calcularPx(40)}
        height={calcularPx(40)}
      />
    </div>
  );
};

const CustomRightArrow = ({ onClick }) => {
  return (
    <div
      style={{
        position: "absolute",
        zIndex: 10,
        right: calcularPx(26),
        cursor: "pointer",
      }}
    >
      <IcoArrow
        className="arrow"
        onClick={() => onClick()}
        width={calcularPx(40)}
        height={calcularPx(40)}
      />
    </div>
  );
};

export { CustomLeftArrow, CustomRightArrow };
