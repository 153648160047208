import React, { Component, createRef } from "react";
import calcularPx from "../calcularPx.js";
import GoogleMapReact from "google-map-react";
import MapMarker from "./mapMarker.jsx";
import Card from "./card.jsx";

class Mapa extends Component {
  state = {
    puntos: this.props.puntos,
    seleccionado: this.props.seleccionado,
  };

  handleApiLoaded(map, maps) {
    map.setClickableIcons(false);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.seleccionado != this.props.seleccionado) {
      this.setState({ seleccionado: this.props.seleccionado });
    }
    if (prevProps.puntos != this.props.puntos) {
      this.setState({ puntos: this.props.puntos });
    }
  }

  seleccionar = (punto) => {
    this.setState({ seleccionado: punto }, () => {
      document.getElementById("cards").scrollTop = document.getElementById(
        "card" + punto.id
      ).offsetTop;
    });
  };

  render() {
    const mobile =
      this.props.mobile &&
      window.innerWidth < parseInt(calcularPx(1070).slice(0, -2));
    const verMapa = this.props.verMapa;
    return (
      <div style={{ display: mobile ? "block" : "flex", width: "100%" }}>
        {verMapa && (
          <React.Fragment>
            <div
              style={{
                margin: "auto",
                position: "relative",
                width: "91.94vw",
                maxWidth: calcularPx(634),
                height: "123.61vw",
                maxHeight: calcularPx(477),
              }}
            >
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyA5GgXeh9KUsPaOCZbS2EQs1JPeoXx6sS0",
                }}
                defaultCenter={{ lat: -34.6111605, lng: -58.4780019 }}
                center={{
                  lat: this.state.seleccionado.lat,
                  lng: this.state.seleccionado.lng,
                }}
                defaultZoom={11.5}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) =>
                  this.handleApiLoaded(map, maps)
                }
              >
                {this.state.puntos.map((e) => {
                  if (e.lat && e.lng) {
                    return (
                      <MapMarker
                        lat={e.lat}
                        lng={e.lng}
                        id={e.id}
                        idSeleccionado={this.state.seleccionado.id}
                        seleccionar={this.seleccionar}
                        width={30}
                        height={30}
                      />
                    );
                  } else {
                    return <></>;
                  }
                })}
              </GoogleMapReact>
            </div>

            {mobile && (
              <div style={{ height: calcularPx(30), width: "100%" }} />
            )}
          </React.Fragment>
        )}

        <div
          id="cards"
          className="scrollSinBarra"
          style={{
            position: "relative",
            border: calcularPx(1) + " solid #D1D1D1",
            borderRadius: calcularPx(5),
            width: mobile ? "91.94vw" : verMapa ? "80vw" : "171.94vw",
            maxWidth: mobile
              ? calcularPx(634)
              : verMapa
              ? calcularPx(340)
              : calcularPx(974),
            height: "min-content",
            maxHeight: calcularPx(477),
            marginLeft: !verMapa || mobile ? "auto" : calcularPx(32),
            marginRight: "auto",
            overflowY: "scroll",
            padding: calcularPx(10),
          }}
        >
          {this.state.puntos.length > 0 ? (
            this.state.puntos.map((p) => (
              <Card
                verMapa={verMapa}
                idSeleccionado={this.state.seleccionado.id}
                seleccionar={this.seleccionar}
                punto={p}
              />
            ))
          ) : (
            <div
              className="tituloPV"
              style={{
                position: "static",
                display: "block",
                alignItems: "center",
                textAlign: "center",
                padding: calcularPx(10),
                fontSize: calcularPx(24),
              }}
            >
              No se encontraron resultados.
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Mapa;
