import React, { Component } from "react";

import { Routes, Route, Navigate } from "react-router-dom";
import calcularPx from "./calcularPx.js";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";

import NavBar from "./navBar/navBar.jsx";
import Home from "../home/paginaHome.jsx";
import Productos from "../productos/paginaProductos.jsx";
import PuntosDeVenta from "../puntosDeVenta/paginaPuntosDeVenta.jsx";
import Posventa from "../posventa/paginaPosventa.jsx";
import PreguntasFrecuentes from "../preguntasFrecuentes/paginaPreguntasFrecuentes.jsx";
import NotFound from "./paginaNotFound.jsx";
import Footer from "./footer.jsx";
import Proximamente from "./paginaProximamente.jsx";
class Sitio extends Component {
  state = {
    width: 0,
    cargo: true,
  };

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);
    this.actualizarAncho = this.actualizarAncho.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.actualizarAncho);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.actualizarAncho);
  }

  actualizarAncho() {
    this.setState({
      width: typeof window !== "undefined" ? window.innerWidth : 0,
    });
  }

  render() {
    return (
      <div>
        <div
          style={{
            position: "relative",
            minHeight: "calc(100vh - " + calcularPx(303.49) + ")",
          }}
        >
          <NavBar />
          <Routes>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<Home />} />
            <Route path="/productos/:linea" element={<Productos />} />
            <Route
              path="/productos/:linea/:modelo/:id/*"
              element={<Productos />}
            />
            {<Route path="/dondeComprar" element={<Proximamente />} />}
            {/*<Route path="/dondeComprar" element={<PuntosDeVenta />} />*/}
            <Route path="/posventa" element={<Posventa />} />
            <Route
              path="/preguntasFrecuentes"
              element={<PreguntasFrecuentes />}
            />
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/404" />} />
          </Routes>
        </div>
        {<Footer />}
      </div>
    );
  }
}

export default withCookies(Sitio);
