import React, { Component } from "react";
import calcularPx from "../general/calcularPx";

class Filtros extends Component {
  state = { filtros: this.props.filtros };

  componentDidUpdate(prevProps) {
    if (prevProps.filtros != this.props.filtros) {
      this.setState({
        filtros: this.props.filtros,
      });
    }
  }

  handleOnChange(f, o) {
    this.props.cambiarFiltro(f, o);
  }

  render() {
    return (
      <div
        className="scrollSinBarra"
        style={{
          borderRadius: calcularPx(30),
          padding: calcularPx(30),
          background: "#F3F3F3",
          position: "relative",
          overflowX: "hidden",
          height: "min-content",
          maxHeight: "100%",
        }}
      >
        <h1 className="filtro">Filtros</h1>
        {this.state.filtros.map((f) => {
          if (f.mostrar)
            return (
              <React.Fragment>
                <div
                  style={{ height: calcularPx(1), backgroundColor: "#C4C4C4" }}
                />
                <div style={{ height: calcularPx(15) }} />
                <h1 className="tituloFiltro">{f.titulo}</h1>
                <div style={{ display: "block" }}>
                  {f.opciones.map((o) => {
                    if (o.mostrar)
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            alignItems: "baseline",
                          }}
                        >
                          <input
                            type="checkbox"
                            value={{ min: o.valorMin, max: o.valorMax }}
                            defaultChecked={o.checked}
                            onChange={() => this.handleOnChange(f, o)}
                          />
                          <h1 className="filtro">{o.nombre}</h1>
                        </div>
                      );
                  })}
                </div>
              </React.Fragment>
            );
        })}
      </div>
    );
  }
}

export default Filtros;
