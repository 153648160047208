import React, { Component } from "react";
import calcularPx from "../calcularPx";
import { ReactComponent as IcoMarker } from "../../../res/mapa/marker-vacio.svg";
import { ReactComponent as IcoTelefono } from "../../../res/mapa/telefono.svg";

class Card extends Component {
  state = { isSeleccionado: false };

  componentDidUpdate(prevProps) {
    if (prevProps.idSeleccionado !== this.props.idSeleccionado)
      this.setState({
        isSeleccionado: this.props.idSeleccionado === this.props.punto.id,
      });
  }

  render() {
    const { id, lat, lng, nombre, domicilio, telefono } = this.props.punto;
    const verMapa = this.props.verMapa;
    return (
      <div id={"card" + id}>
        <div
          onClick={
            verMapa
              ? () =>
                  this.props.seleccionar({
                    id: id,
                    lat: Number(lat),
                    lng: Number(lng),
                  })
              : () => {}
          }
          style={{
            display: "block",
            width: "100%",
            height: "min-content",
            border: this.state.isSeleccionado
              ? calcularPx(3) + " solid #EE700F"
              : calcularPx(1) + " solid #D1D1D1",
            borderRadius: calcularPx(5),
            padding: calcularPx(10),
          }}
        >
          {nombre && nombre.trim().length > 0 && (
            <div className="tituloPV" style={{ paddingBottom: calcularPx(10) }}>
              {nombre.toUpperCase()}
            </div>
          )}
          <div
            style={{
              display: verMapa ? "" : "flex",
              gap: verMapa ? "0" : calcularPx(32),
            }}
          >
            {domicilio && domicilio.trim().length > 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: verMapa ? "100%" : "50%",
                  paddingBottom: calcularPx(10),
                }}
              >
                <IcoMarker
                  style={{ flexShrink: 0 }}
                  width={calcularPx(25)}
                  height={calcularPx(25)}
                />
                <div
                  className="dropPV"
                  style={{
                    paddingLeft: calcularPx(5),
                  }}
                >
                  {domicilio.toUpperCase()}
                </div>
              </div>
            )}
            {telefono && telefono.trim().length > 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: verMapa ? "100%" : "50%",
                  paddingBottom: calcularPx(10),
                }}
              >
                <IcoTelefono
                  style={{ flexShrink: 0 }}
                  width={calcularPx(25)}
                  height={calcularPx(25)}
                />
                <div
                  className="dropPV"
                  style={{
                    paddingLeft: calcularPx(5),
                  }}
                >
                  {telefono.toUpperCase()}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Card;
