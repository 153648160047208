import React, { Component } from "react";
import calcularPx from "./calcularPx";
import { ReactComponent as Logo } from "../../res/iconos/coppens.svg";
import { ReactComponent as IcoInstagram } from "../../res/iconos/instagram.svg";
import { ReactComponent as IcoFacebook } from "../../res/iconos/facebook.svg";

const Footer = () => {
  return (
    <React.Fragment>
      <div
        style={{
          width: "100%",
          position: "relative",
          textAlign: "center",
        }}
      >
        <div style={{ height: calcularPx(30), width: "100%" }} />
        <div
          style={{
            height: calcularPx(1),
            width: "90.56%",
            margin: "auto",
            backgroundColor: "#FFB000",
          }}
        />
        <div style={{ height: calcularPx(43), width: "100%" }} />

        <a
          href="https://www.instagram.com/uniqoar"
          target="_blank"
          style={{
            display: "inline-block",
            marginRight: calcularPx(15),
          }}
        >
          <IcoInstagram width={calcularPx(30)} height={calcularPx(30)} />
        </a>
        {/*
          <a
            href="https://www.facebook.com/people/Emeg%C3%A9-Calefacci%C3%B3n/100052003101632/?ref=page_internal"
            target="_blank"
            style={{
              display: "inline-block",
              marginLeft: calcularPx(15),
            }}
          >
            <IcoFacebook width={calcularPx(30)} height={calcularPx(30)} />
          </a>
          */}

        <div style={{ height: calcularPx(30), width: "100%" }} />

        <Logo width={calcularPx(243)} height={calcularPx(27)} />

        <div style={{ height: calcularPx(30), width: "100%" }} />

        <div
          style={{
            height: calcularPx(130),
            width: "100%",
            backgroundColor: "#FFB000",
            color: "#FFFFFF",
            textAlign: "center",
            lineHeight: calcularPx(130),
            fontSize: calcularPx(20),
            borderTopLeftRadius: calcularPx(35),
            borderTopRightRadius: calcularPx(35),
          }}
        >
          Mar del Plata, Argentina
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
