import React, { Component, createRef } from "react";
import calcularPx from "../general/calcularPx";
import { ReactComponent as IcoCerrar } from "../../res/iconos/cerrar.svg";
import { Link, useLocation } from "react-router-dom";
import Carousel from "react-multi-carousel";
import { CustomLeftArrow, CustomRightArrow } from "../general/customArrow";

function withLocation(Component) {
  function ComponentWithLocation(props) {
    let location = useLocation();
    return <Component {...props} location={location} />;
  }
  return ComponentWithLocation;
}

class Detalle extends Component {
  state = {
    producto: this.props.producto,
    imgUrl: this.props.producto.url,
    manualUrl: null,
    location: this.props.location,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    window.scrollTo(0, 0);
    if (prevProps.location !== this.props.location) {
      this.setState({ location: this.props.location });
    }
    if (prevProps.producto !== this.props.producto) {
      this.setState({
        producto: this.props.producto,
        imgUrl: this.props.imgUrl,
      });
    } else if (prevProps.url !== this.props.url) {
      this.setState({ imgUrl: this.props.url });
    }
  }

  async descargarManual() {
    const res = await fetch(this.props.producto.manualPath);
    const blob = await res.blob();
    const url = URL.createObjectURL(blob);
    this.setState({ manualUrl: url }, () => {
      document.getElementById("botonManual").click();
    });
  }

  render() {
    const mobile = window.innerWidth < parseInt(calcularPx(1018).slice(0, -2));
    const responsive = {
      todo: { breakpoint: { max: 10000, min: 0 }, items: 1 },
    };
    const { producto } = this.state;
    const { search } = this.state.location;
    const pathname = this.state.location.pathname
      .split("/")
      .slice(0, -2)
      .join("/");
    const link = pathname + search;
    return mobile ? (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: calcularPx(15),
          position: "relative",
          width: "80%",
          height: "90%",
          maxHeight: calcularPx(1100),
          borderRadius: calcularPx(20),
          padding: "5%",
          background: "white",
          zIndex: 51,
        }}
      >
        <div style={{ maxWidth: 0.9 * window.innerWidth }}>
          <Carousel
            responsive={responsive}
            showDots={true}
            draggable={false}
            infinite={true}
            autoPlay={true}
            partialVisible={false}
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
          >
            {producto.imgPaths.map((e, i) => {
              return (
                <img
                  id={"imgDetalle" + i}
                  src={e}
                  alt=""
                  style={{
                    position: "relative",
                    flexShrink: 0,
                    alignSelf: "center",
                    width: "auto",
                    maxWidth: "100%",
                    maxHeight: calcularPx(500),
                    objectFit: "scale-down",
                  }}
                />
              );
            })}
          </Carousel>
        </div>

        <div style={{ height: calcularPx(40) }} />
        <div
          style={{
            position: "relative",
            background: "#FFB000",
            color: "#FFFFFF",
            fontFamily: "Nunito",
            fontWeight: 700,
            width: "95%",
            height: calcularPx(56),
            lineHeight: calcularPx(56),
            textAlign: "right",
            marginLeft: "-7.2%",
            paddingLeft: calcularPx(40),
            paddingRight: calcularPx(40),
            borderTopRightRadius: calcularPx(30),
            borderBottomRightRadius: calcularPx(30),
          }}
        >
          {producto.modelo}
        </div>
        <h1 className="subtituloDetalle">{producto.nombre}</h1>
        <h1
          className="descDetalle"
          style={{
            flexShrink: 1,
            height: "auto",
          }}
        >
          {producto.descripcion}
        </h1>
        <div
          style={{
            position: "relative",
            display: "flex",
            width: "100%",
            flexDirection: "row",
            gap: calcularPx(5),
            color: "#727272",
            fontSize: calcularPx(14),
            fontFamily: "Nunito",
            lineHeight: calcularPx(20),
          }}
        >
          <div
            style={{
              flex: 1,
              borderRadius: calcularPx(5),
              background: "#F5F5F5",
              width: "50%",
              height: calcularPx(100),
              padding: calcularPx(10),
            }}
          >
            {producto.superficieCalefaccionM2 ? (
              <>
                <b>Sup. a calefaccionar</b>
                <br />
                {producto.superficieCalefaccionM2} m2
                <br />
                <div style={{ fontSize: calcularPx(12) }}>
                  *Con altura de techo de hasta 2,7 m aprox
                </div>
              </>
            ) : (
              <>
                <b>Peso vacío:</b>
                <br />
                {producto.pesoKg} kg
                <br />
                <b>Peso con agua:</b>
                <br />
                {producto.pesoKg + producto.capacidadLt} kg
              </>
            )}
          </div>
          <div
            style={{
              flex: 1,
              borderRadius: calcularPx(5),
              background: "#F5F5F5",
              width: "50%",
              height: calcularPx(100),
              padding: calcularPx(10),
            }}
          >
            <b>Medidas</b>
            <br />
            Alto: {producto.altoCm} cm
            <br />
            Ancho: {producto.anchoCm ?? producto.diametroCm} cm
            <br />
            Profundidad: {producto.profundidadCm ?? producto.diametroCm} cm
          </div>
        </div>
        <button
          onClick={() => this.descargarManual()}
          className="botonManual"
          style={{
            position: "relative",
            flexShrink: 0,
            width: "100%",
          }}
        >
          Descargar manual
        </button>
        <a
          id="botonManual"
          href={this.state.manualUrl}
          download={producto.manualPath.split("/").slice(-1)}
          style={{ display: "none" }}
        />
        <Link
          to="/puntosDeVenta"
          className="botonComprar"
          style={{
            position: "relative",
            flexShrink: 0,
            width: "100%",
          }}
        >
          Dónde comprar
        </Link>

        <Link to={link}>
          <IcoCerrar
            width={calcularPx(44)}
            height={calcularPx(44)}
            style={{
              position: "absolute",
              top: calcularPx(-22),
              right: calcularPx(-22),
            }}
          />
        </Link>
      </div>
    ) : (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: calcularPx(32),
          justifyContent: "flex-start",
          alignItems: "start",
          position: "relative",
          width: calcularPx(958),
          height: calcularPx(625),
          maxHeight: calcularPx(900),
          borderRadius: calcularPx(20),
          padding: "0",
          background: "white",
          zIndex: 51,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: calcularPx(50),
            background: "#FFB000",
            color: "#FFFFFF",
            fontFamily: "Nunito",
            fontWeight: 700,
            width: "auto",
            height: calcularPx(56),
            lineHeight: calcularPx(56),
            textAlign: "left",
            paddingLeft: calcularPx(462),
            paddingRight: calcularPx(40),
            borderTopRightRadius: calcularPx(30),
            borderBottomRightRadius: calcularPx(30),
          }}
        >
          {producto.modelo}
        </div>
        <div
          style={{
            position: "relative",
            flexShrink: 0,
            width: "100%",
            maxWidth: calcularPx(432),
          }}
        >
          <Carousel
            responsive={responsive}
            showDots={true}
            draggable={false}
            infinite={true}
            autoPlay={true}
            partialVisible={false}
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
          >
            {producto.imgPaths.map((e, i) => {
              return (
                <img
                  id={"imgDetalle" + i}
                  src={e}
                  alt=""
                  style={{
                    position: "relative",
                    flexShrink: 0,
                    width: "100%",
                    maxWidth: calcularPx(432),
                    height: "auto",
                    height: calcularPx(497),
                    objectFit: "contain",
                  }}
                />
              );
            })}
          </Carousel>

          <div
            style={{
              position: "relative",
              display: "flex",
              width: "100%",
              flexDirection: "row",
              gap: calcularPx(16),
              paddingLeft: calcularPx(46),
              paddingRight: calcularPx(46),
              color: "#727272",
              fontSize: calcularPx(14),
              fontFamily: "Nunito",
              lineHeight: calcularPx(20),
            }}
          >
            <div
              style={{
                flex: 1,
                borderRadius: calcularPx(5),
                background: "#F5F5F5",
                width: "50%",
                height: calcularPx(100),
                padding: calcularPx(7),
              }}
            >
              {producto.superficieCalefaccionM2 ? (
                <>
                  <b>Sup. a calefaccionar</b>
                  <br />
                  {producto.superficieCalefaccionM2} m2
                  <br />
                  <div style={{ fontSize: calcularPx(12) }}>
                    *Con altura de techo de hasta 2,7 m aprox
                  </div>
                </>
              ) : (
                <>
                  <b>Peso vacío:</b>
                  <br />
                  {producto.pesoKg} kg
                  <br />
                  <b>Peso con agua:</b>
                  <br />
                  {producto.pesoKg + producto.capacidadLt} kg
                </>
              )}
            </div>
            <div
              style={{
                flex: 1,
                borderRadius: calcularPx(5),
                background: "#F5F5F5",
                width: "50%",
                height: calcularPx(100),
                padding: calcularPx(7),
              }}
            >
              <b>Medidas</b>
              <br />
              Alto: {producto.altoCm} cm
              <br />
              Ancho: {producto.anchoCm ?? producto.diametroCm} cm
              <br />
              Profundidad: {producto.profundidadCm ?? producto.diametroCm} cm
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: calcularPx(20),
            width: "100%",
            maxHeight: calcularPx(625),
            paddingRight: calcularPx(32),
          }}
        >
          <div style={{ height: calcularPx(146) }} />
          <div>
            <h1 className="subtituloDetalle">{producto.nombre}</h1>
          </div>
          <h1
            className="descDetalle"
            style={{
              flexShrink: 1,
              height: "auto",
            }}
          >
            {producto.descripcion}
          </h1>
          <button
            onClick={() => this.descargarManual()}
            className="botonManual"
            style={{ position: "relative", width: "100%", flexShrink: 0 }}
          >
            Descargar manual
          </button>
          <a
            id="botonManual"
            href={this.state.manualUrl}
            download={producto.manualPath.split("/").slice(-1)}
            style={{ display: "none" }}
          />
          <Link
            to="/puntosDeVenta"
            className="botonComprar"
            style={{ position: "relative", width: "100%", flexShrink: 0 }}
          >
            Dónde comprar
          </Link>

          <Link to={link}>
            <IcoCerrar
              width={calcularPx(44)}
              height={calcularPx(44)}
              style={{
                position: "absolute",
                top: calcularPx(-22),
                right: calcularPx(-22),
              }}
            />
          </Link>
        </div>
      </div>
    );
  }
}

export default withLocation(Detalle);
