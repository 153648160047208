import React, { Component } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import calcularPx from "./calcularPx";

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/home");
    }, 4000);
  }, []);

  return (
    <React.Fragment>
      <div style={{ height: calcularPx(80), width: "100%" }} />
      <div
        style={{
          textAlign: "center",
        }}
      >
        <h1 className="tituloError">Error 404</h1>
        <h1 className="textoError">La página no existe.</h1>
        <h1 className="textoError">Redirigiendo a la página principal...</h1>
      </div>
    </React.Fragment>
  );
};

export default NotFound;
