import React, { Component } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import calcularPx from "./calcularPx";

const Proximamente = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/home");
    }, 4000);
  }, []);

  return (
    <React.Fragment>
      <div style={{ height: calcularPx(30), width: "100%" }} />
      <img
        src={require("../../res/banners/proximamente.jpg")}
        style={{
          position: "relative",
          objectFit: "cover",
          objectPosition: "center",
          width: "100vw",
        }}
      />
    </React.Fragment>
  );
};

export default Proximamente;
