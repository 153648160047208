import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Sitio from "./Sitio";

class App extends Component {
  state = {};
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Sitio />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
