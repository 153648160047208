import React, { Component } from "react";
import calcularPx from "../general/calcularPx";
import Producto from "./producto.jsx";
import Detalle from "./detalle.jsx";
import Filtros from "./filtros.jsx";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as IcoFiltros } from "../../res/iconos/filtros.svg";
import { ReactComponent as IcoCerrar } from "../../res/iconos/cerrar.svg";

function withRouterAndNavigate(Component) {
  function ComponentWithRouterAndNavigate(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouterAndNavigate;
}

class Productos extends Component {
  state = {
    linea: this.props.params.linea,
    productos: [],
    productosFiltrados: [],
    filtros: this.getFiltros(
      this.props.params.linea,
      new URLSearchParams(this.props.location.search)
    ),
    filtrosAplicados: [],
    productoDetalle: null,
    mostrarFiltros: false,
    cargo: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getProductos();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.params.id !== this.props.params.id) {
      let producto = null;
      if (this.props.params.id) {
        producto = this.state.productos.find(
          (e) =>
            e.id == this.props.params.id &&
            e.modelo.replace("/", "-") === this.props.params.modelo
        );
      }
      this.setState({ productoDetalle: producto });
    }
    if (prevProps.params.linea !== this.props.params.linea) {
      this.setState(
        {
          linea: this.props.params.linea,
          filtros: [],
          filtrosAplicados: [],
          productos: [],
          productosFiltrados: [],
          cargo: false,
        },
        () => {
          this.setState(
            {
              productos: [],
              productosFiltrados: [],
              filtros: [],
              cargo: false,
            },
            () => this.getProductos()
          );
        }
      );
    }
  }

  getProductos() {
    fetch("/api/productos/?linea=" + this.state.linea)
      .then((res) => res.json())
      .then((data) => {
        let producto = null;
        data.forEach((e) => {
          e.url = null;
          e.setUrl = (url) => {
            e.url = url;
            if (this.state.productoDetalle === e) this.setState(this.state);
          };
        });
        if (this.props.params.id) {
          producto = data.find(
            (e) =>
              e.id == this.props.params.id &&
              e.modelo.replace("/", "-") === this.props.params.modelo
          );
        }
        let queryParams = new URLSearchParams(this.props.location.search);
        this.setState(
          { filtros: this.getFiltros(this.props.params.linea, queryParams) },
          () => {
            let filtrosCustom = this.getFiltrosCustomAplicadosIniciales(
              this.props.params.linea,
              queryParams
            );
            let productosFiltradosIniciales = this.getProductosFiltrados(
              data,
              filtrosCustom
            );

            let filtrosAdicionales = this.getFiltrosInicialesAdicionales(
              filtrosCustom,
              this.props.params.linea,
              queryParams
            );
            let productosFiltrados = this.getProductosFiltrados(
              productosFiltradosIniciales,
              filtrosAdicionales
            );

            this.setState({
              productos: productosFiltradosIniciales,
              productosFiltrados: productosFiltrados,
              productoDetalle: producto,
              cargo: true,
            });
          }
        );
      });
  }

  getFiltrosInicialesAdicionales(filtrosAplicados, linea, queryParams) {
    let filtros = [];
    switch (linea) {
      case "termotanquesGas":
        let ar = queryParams.get("ar");
        if (ar === "true") {
          let f = this.state.filtros.find((f) => f.campo === "recuperacion");
          let o = f.opciones.find((o) => o.nombre === "Alta recuperación");
          filtros = this.agregarAFiltrosAplicados(filtrosAplicados, f, o);
        }
        break;
      default:
        break;
    }
    return filtros;
  }

  getFiltrosCustomAplicadosIniciales(linea, queryParams) {
    let filtrosCustom = [];
    switch (linea) {
      case "calefactoresRecomendados":
        let kcal = queryParams.get("kcal");
        let sce = queryParams.get("sce");
        let tb = queryParams.get("tb");
        let tbu = queryParams.get("tbu");
        filtrosCustom = [
          {
            campo: "kcal",
            opcion: {
              valorMin: parseInt(kcal),
              valorMax: 99999,
              checked: true,
            },
          },
          {
            campo: "sce",
            opcion: {
              valorMin: 0,
              valorMax: sce === "true" ? 1 : 0,
              checked: true,
            },
          },
          {
            campo: "tiraje",
            opcion: {
              valorMin: 0,
              valorMax: tb === "false" ? 0 : 3,
              checked: true,
            },
          },
          {
            campo: "tiraje",
            opcion: {
              valorMin: tb === "false" ? 2 : 0,
              valorMax: 3,
              checked: true,
            },
          },
          {
            campo: "tbu",
            opcion: {
              valorMin: 0,
              valorMax: tbu === "true" ? 1 : 0,
              checked: true,
            },
          },
        ];
        break;
      case "termotanquesRecomendados":
        let litros = parseInt(queryParams.get("litros"));
        let e = queryParams.get("e");
        let g = queryParams.get("g");
        filtrosCustom = [
          {
            campo: "capacidadLt",
            opcion: {
              valorMin: litros,
              valorMax: 99999,
              checked: true,
            },
          },
          {
            campo: "energia",
            opcion: {
              valorMin: e === "true" ? "e" : "g",
              valorMax: g === "true" ? "g" : "e",
              checked: true,
            },
          },
        ];
        break;
      default:
        filtrosCustom = [];
    }

    let filtrosAplicadosIniciales = [];
    filtrosCustom.forEach((f) => {
      filtrosAplicadosIniciales = this.agregarAFiltrosAplicados(
        filtrosAplicadosIniciales,
        f,
        f.opcion
      );
    });

    return filtrosAplicadosIniciales;
  }

  getFiltros(linea, queryParams) {
    let filtros = [];
    switch (linea) {
      case "convectores":
        filtros = [
          {
            titulo: "Tipo de salida",
            campo: "salidaConcentrica",
            mostrar: true,
            opciones: [
              {
                nombre: "Concentrica",
                valorMin: 1,
                valorMax: 1,
                mostrar: true,
                checked: false,
              },
              {
                nombre: "Sin salida",
                valorMin: 0,
                valorMax: 0,
                mostrar: true,
                checked: false,
              },
            ],
          },
          {
            titulo: "Tipo de tiraje",
            campo: "tiraje",
            mostrar: true,
            opciones: [
              {
                nombre: "Tiro balanceado",
                valorMin: 1,
                valorMax: 3,
                mostrar: true,
                checked: false,
              },
              {
                nombre: 'Tiro balanceado en "U"',
                valorMin: 2,
                valorMax: 3,
                mostrar: true,
                checked: false,
              },
              {
                nombre: "Sin tiraje",
                valorMin: 0,
                valorMax: 0,
                mostrar: true,
                checked: false,
              },
            ],
          },
          {
            titulo: "Calorias",
            campo: "kcal",
            mostrar: true,
            opciones: [
              {
                nombre: "2000 - 3000 kcal",
                valorMin: 2000,
                valorMax: 3000,
                mostrar: true,
                checked: false,
              },
              {
                nombre: "3500 - 5000 kcal",
                valorMin: 3500,
                valorMax: 5000,
                mostrar: true,
                checked: false,
              },
              {
                nombre: "5500 - 8000 kcal",
                valorMin: 5500,
                valorMax: 8000,
                mostrar: true,
                checked: false,
              },
            ],
          },
          {
            titulo: "Superficie a calefaccionar",
            campo: "volumenCalefaccionM3",
            mostrar: true,
            opciones: [
              {
                nombre: "40 - 70 m3",
                valorMin: 40,
                valorMax: 70,
                mostrar: true,
                checked: false,
              },
              {
                nombre: "70 - 100 m3",
                valorMin: 70,
                valorMax: 100,
                mostrar: true,
                checked: false,
              },
              {
                nombre: "100 - 160 m3",
                valorMin: 100,
                valorMax: 160,
                mostrar: true,
                checked: false,
              },
            ],
          },
        ];
        break;
      case "termotanquesGas":
        filtros = [
          {
            titulo: "Capacidad de agua",
            campo: "capacidadLt",
            mostrar: true,
            opciones: [
              {
                nombre: "50 litros",
                valorMin: 50,
                valorMax: 50,
                mostrar: true,
                checked: false,
              },
              {
                nombre: "80 litros",
                valorMin: 80,
                valorMax: 80,
                mostrar: true,
                checked: false,
              },
              {
                nombre: "120 litros",
                valorMin: 120,
                valorMax: 120,
                mostrar: true,
                checked: false,
              },
            ],
          },
        ];
        break;
      default:
        filtros = [];
        break;
    }
    return filtros;
  }

  ocultarFiltros() {
    this.setState({ mostrarFiltros: false });
  }

  cambiarFiltro(f, o) {
    let filtros = [...this.state.filtros];
    let opcion = filtros.find((fs) => f === fs).opciones.find((os) => o === os);
    opcion.checked = !opcion.checked;

    let filtrosAplicados = this.agregarAFiltrosAplicados(
      [...this.state.filtrosAplicados],
      f,
      opcion
    );
    this.setState(
      {
        filtros: filtros,
        filtrosAplicados: filtrosAplicados,
        productosFiltrados: [],
        cargo: false,
      },
      () =>
        this.setState({
          productosFiltrados: this.getProductosFiltrados(
            this.state.productos,
            filtrosAplicados
          ),
          cargo: true,
        })
    );
  }

  agregarAFiltrosAplicados(filtrosAplicados, f, opcion) {
    let indice = filtrosAplicados.findIndex((fa) => fa.campo === f.campo);
    if (opcion.checked) {
      if (indice === -1) {
        filtrosAplicados.push({
          campo: f.campo,
          valores: [{ min: opcion.valorMin, max: opcion.valorMax }],
        });
      } else {
        filtrosAplicados[indice].valores.push({
          min: opcion.valorMin,
          max: opcion.valorMax,
        });
      }
    } else {
      if (indice !== -1) {
        filtrosAplicados[indice].valores = filtrosAplicados[
          indice
        ].valores.filter((v) => {
          return v.min !== opcion.valorMin || v.max !== opcion.valorMax;
        });
        if (filtrosAplicados[indice].valores.length === 0) {
          filtrosAplicados.splice(indice, 1);
        }
      }
    }
    return filtrosAplicados;
  }

  getProductosFiltrados(productos, filtrosAplicados) {
    let productosFiltradosTotal = [...productos];
    filtrosAplicados.forEach((c) => {
      let productosFiltradosCampo = [];
      c.valores.forEach((v) => {
        let productosFiltrados = productosFiltradosTotal.filter((p) => {
          return v.min <= p[c.campo] && p[c.campo] <= v.max;
        });
        productosFiltradosCampo = productosFiltradosCampo.concat(
          productosFiltrados.filter((p) => !productosFiltradosCampo.includes(p))
        );
      });
      productosFiltradosTotal = productosFiltradosTotal.filter((p) =>
        productosFiltradosCampo.includes(p)
      );
    });

    return productosFiltradosTotal;
  }

  render() {
    const mobileBanner = window.innerWidth < 834;
    const mobile = window.innerWidth < parseInt(calcularPx(1025).slice(0, -2));
    const anchoFiltros = parseInt(calcularPx(mobile ? 27 : 414).slice(0, -2));
    const cantColumnas =
      (window.innerWidth - anchoFiltros) /
      parseInt(calcularPx(449).slice(0, -2));
    let titulo;
    switch (this.state.linea) {
      case "termotanquesGas":
        titulo = "Termotanques Uniqo";
        break;
      case "convectores":
        titulo = "Convectores Uniqo";
        break;
      default:
        titulo = "";
        break;
    }

    return (
      <React.Fragment>
        <div
          style={{
            display: this.state.productoDetalle ? "" : "none",
            position: "absolute",
            width: "100%",
            height:
              "calc(100% - " + calcularPx(102) + " + " + calcularPx(30) + ")",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              zIndex: 50,
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              paddingTop: calcularPx(64),
              width: "100%",
              height: "100%",
            }}
          >
            <CSSTransition
              in={this.state.productoDetalle}
              timeout={250}
              classNames="productoHover"
              unmountOnExit
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",
                  zIndex: 51,
                }}
              >
                {this.state.productoDetalle && (
                  <Detalle
                    producto={this.state.productoDetalle}
                    url={
                      this.state.productoDetalle
                        ? this.state.productoDetalle.url
                        : null
                    }
                    linea={this.state.linea}
                  />
                )}
              </div>
            </CSSTransition>
          </div>
        </div>

        {mobile && this.state.mostrarFiltros && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "calc(100% - " + calcularPx(72) + ")",
            }}
          >
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                zIndex: 50,
              }}
            />
            <div
              style={{
                position: "fixed",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                paddingTop: "7%",
                width: "100%",
                height: "100%",
                zIndex: 101,
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "80%",
                  height: "auto",
                  maxHeight: "90%",
                  background: "white",
                  padding: "5%",
                  borderRadius: calcularPx(35),
                  zIndex: 51,
                }}
              >
                <Filtros
                  filtros={this.state.filtros}
                  cambiarFiltro={(f, o) => this.cambiarFiltro(f, o)}
                />
                <button
                  onClick={() => {
                    this.ocultarFiltros();
                  }}
                  style={{
                    position: "absolute",
                    top: calcularPx(-22),
                    right: calcularPx(-22),
                    background: "transparent",
                    border: "none",
                  }}
                >
                  <IcoCerrar width={calcularPx(44)} height={calcularPx(44)} />
                </button>
              </div>
            </div>
          </div>
        )}

        {this.state.linea === "termotanquesGas" && (
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "auto",
              textJustify: "left",
              left: 0,
              top: 0,
            }}
          >
            <img
              src={require(mobileBanner
                ? "../../res/banners/termotanquesMobile.jpg"
                : "../../res/banners/termotanquesDesktop.jpg")}
              style={{
                position: "relative",
                objectFit: "cover",
                objectPosition: "right",
                width: "100%",
                height: "auto",
                left: 0,
                top: 0,
                zIndex: -1,
              }}
            />
            <img
              src={require("../../res/banners/termotanquesTexto.png")}
              style={{
                position: "absolute",
                objectFit: "cover",
                objectPosition: "right",
                top: 0,
                bottom: 0,
                marginTop: "auto",
                marginBottom: "auto",
                maxHeight: "60%",
                maxWidth: mobileBanner ? "40%" : "",
                right: "5%",
                zIndex: -1,
              }}
            />
          </div>
        )}
        {this.state.linea === "convectores" && (
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "auto",
              textJustify: "left",
              left: 0,
              top: 0,
            }}
          >
            <img
              src={require(mobileBanner
                ? "../../res/banners/convectoresMobile.jpg"
                : "../../res/banners/convectoresDesktop.jpg")}
              style={{
                position: "relative",
                objectFit: "cover",
                objectPosition: "right",
                width: "100%",
                height: "auto",
                left: 0,
                top: 0,
                zIndex: -1,
              }}
            />
            <img
              src={require("../../res/banners/convectoresTexto.png")}
              style={{
                position: "absolute",
                objectFit: "cover",
                objectPosition: "right",
                top: 0,
                bottom: 0,
                marginTop: "auto",
                marginBottom: "auto",
                maxHeight: "60%",
                maxWidth: mobileBanner ? "40%" : "",
                right: "2%",
                zIndex: -1,
              }}
            />
          </div>
        )}
        <div style={{ height: calcularPx(20) }} />

        <div
          style={{
            display: "flex",
            minHeight: calcularPx(863),
            flexDirection: mobile ? "column" : "row",
            gap: mobile ? calcularPx(23) : "",
          }}
        >
          {mobile && this.state.filtros.length > 0 ? (
            <button
              className="botonFiltros"
              onClick={() => {
                this.setState({ mostrarFiltros: !this.state.mostrarFiltros });
              }}
              style={{
                position: "relative",
                flexShrink: 0,
                left: "calc(-45.87% + " + calcularPx(46.46) + ")",
                background: "transparent",
                border: "none",
              }}
            >
              <IcoFiltros
                width={calcularPx(28.46)}
                height={calcularPx(28.46)}
              />
              <div
                style={{
                  display: "inline",
                  position: "relative",
                  paddingLeft: calcularPx(12),
                }}
              >
                Filtros
              </div>
            </button>
          ) : (
            <div
              style={{
                position: "relative",
                width: mobile ? "100%" : calcularPx(414),
                paddingLeft: "3.44%",
                paddingRight: calcularPx(28),
                flexShrink: 0,
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: calcularPx(17),
                  left: 0,
                  background: "#FFB000",
                  borderTopRightRadius: calcularPx(20),
                  borderBottomRightRadius: calcularPx(20),
                  width: calcularPx(655),
                  height: calcularPx(50),
                  color: "#FFFFFF",
                  fontSize: calcularPx(18),
                  fontWeight: 700,
                  fontFamily: "Nunito",
                  lineHeight: calcularPx(50),
                  paddingRight: calcularPx(30),
                  textAlign: "right",
                }}
              >
                {titulo}
              </div>
              {this.state.filtros.length > 0 && (
                <Filtros
                  filtros={this.state.filtros}
                  cambiarFiltro={(f, o) => this.cambiarFiltro(f, o)}
                />
              )}
            </div>
          )}

          <div style={{ paddingTop: calcularPx(75) }}>
            <div
              style={{
                display: "grid",
                flexShrink: 0,
                gridAutoColumns: calcularPx(422),
                gridTemplateColumns: "auto ".repeat(cantColumnas),
                gridAutoRows: calcularPx(538),
                placeItems: "center",
                placeContent: "center",
                width: "100%",
                gridGap: calcularPx(27),
              }}
            >
              {this.state.productosFiltrados.length > 0
                ? this.state.productosFiltrados.map((e) => (
                    <Producto producto={e} setUrl={e.setUrl.bind(this)} />
                  ))
                : this.state.cargo && (
                    <h1
                      className="textoError"
                      style={{ padding: mobile ? "10%" : 0 }}
                    >
                      No se encontraron productos con los filtros seleccionados.
                    </h1>
                  )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouterAndNavigate(Productos);
