import React, { Component } from "react";
import { ReactComponent as IcoMarker } from "../../../res/mapa/marker.svg";

class MapMarker extends Component {
  state = { isSeleccionado: false };

  componentDidUpdate(prevProps) {
    if (prevProps.idSeleccionado !== this.props.idSeleccionado)
      this.setState({
        isSeleccionado: this.props.idSeleccionado === this.props.id,
      });
  }

  render() {
    let { width, height } = this.props;
    if (this.state.isSeleccionado) {
      width *= 1.4;
      height *= 1.4;
    }
    return (
      <div
        onClick={() =>
          this.props.seleccionar({
            id: this.props.id,
            lat: Number(this.props.lat),
            lng: Number(this.props.lng),
          })
        }
        style={{
          position: "relative",
          width: "min-content",
          height: "min-content",
          left: "calc(-1px * " + width + " /2)",
          top: "calc(-1px * " + height + ")",
          zIndex: this.state.isSeleccionado ? 51 : 50,
        }}
      >
        <IcoMarker
          width={width}
          height={height}
          fill={this.state.isSeleccionado ? "#F06311" : "#FF9B00"}
        />
      </div>
    );
  }
}

export default MapMarker;
