import React, { Component } from "react";
import calcularPx from "../calcularPx.js";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../../res/iconos/logo.svg";
import { ReactComponent as IcoMenu } from "../../../res/iconos/menu.svg";
import Menu from "./menu.jsx";
import { CSSTransition } from "react-transition-group";

class NavBar extends Component {
  state = { mostrarMenu: false };

  ocultarMenu() {
    this.setState({ mostrarMenu: false });
  }

  render() {
    const mobile = window.innerWidth < 1000;

    return (
      <div
        style={{
          top: 0,
          position: "sticky",
          background: "#ffb000",
          height: calcularPx(102),
          zIndex: 100,
        }}
      >
        <Link
          to="/home"
          onClick={this.ocultarMenu}
          style={{
            position: "relative",
            left: mobile ? "3.44%" : calcularPx(61),
            top: calcularPx(31),
          }}
        >
          <Logo width={calcularPx(170)} height={calcularPx(45)} />
        </Link>
        {mobile && (
          <React.Fragment>
            <button
              onClick={() => {
                this.setState({ mostrarMenu: !this.state.mostrarMenu });
              }}
              style={{
                position: "absolute",
                top: calcularPx(34),
                right: "calc(3.44% + " + calcularPx(34.79) + ")",
                background: "transparent",
                border: "none",
              }}
            >
              <IcoMenu
                style={{ position: "absolute" }}
                width={calcularPx(34.79)}
                height={calcularPx(26.88)}
              />
            </button>
            <CSSTransition
              in={this.state.mostrarMenu}
              timeout={100}
              classNames="opacidad"
              unmountOnExit
            >
              <Menu
                style={{ position: "absolute" }}
                mobile={mobile}
                ocultar={this.ocultarMenu.bind(this)}
              />
            </CSSTransition>
          </React.Fragment>
        )}
        {!mobile && (
          <Menu
            style={{ position: "absolute" }}
            mobile={mobile}
            ocultar={this.ocultarMenu.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default NavBar;
