import React, { Component } from "react";
import { Link } from "react-router-dom";
import calcularPx from "../general/calcularPx.js";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CustomLeftArrow, CustomRightArrow } from "../general/customArrow";

const BannersMobile = ({ banners }) => {
  const responsive = {
    todo: { breakpoint: { max: 10000, min: 0 }, items: 1 },
  };
  return (
    <React.Fragment>
      <div
        style={{
          position: "relative",
          maxWidth: "100%",
          overflow: "hidden",
          zIndex: 0,
        }}
      >
        <Carousel
          responsive={responsive}
          showDots={true}
          draggable={false}
          infinite={true}
          autoPlay={true}
          partialVisible={false}
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
        >
          {banners.primero
            .filter((e) => e.plataforma == 1 || e.plataforma == 2)
            .map((e, i) => {
              return (
                <Link to={e.link}>
                  <div
                    style={{
                      maxHeight: "822px",
                    }}
                  >
                    <img
                      alt={"banner1-" + i}
                      src={e.url}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </Link>
              );
            })}
        </Carousel>
      </div>

      <div style={{ height: calcularPx(64), width: "100%" }} />

      <Link to={"/productos/termotanquesGas"}>
        <div
          style={{
            position: "relative",
            marginLeft: "3.44%",
            marginRight: "3.44%",
            borderTopLeftRadius: calcularPx(35),
            borderTopRightRadius: calcularPx(35),
            background: "#FFB000",
            height: calcularPx(382),
            zIndex: -2,
          }}
        >
          <img
            src={require("../../res/banners/home-termotanques.png")}
            style={{
              position: "absolute",
              objectFit: "cover",
              width: calcularPx(639),
              maxWidth: "70vw",
              top: "calc(" + calcularPx(290) + " - (9.66vw))",
              left: "-6%",
              height: "auto",
              zIndex: 0,
            }}
          />
          <div style={{ height: calcularPx(36), width: "100%" }} />
          <div
            style={{
              color: "#FFFFFF",
              textAlign: "right",
              fontWeight: 700,
              lineHeight: calcularPx(35),
              fontSize: calcularPx(35),
              paddingRight: calcularPx(44),
              fontFamily: "Nunito",
            }}
          >
            Termotanques
          </div>
          <div
            style={{
              color: "#FFFFFF",
              textAlign: "right",
              fontWeight: 400,
              lineHeight: calcularPx(35),
              fontSize: calcularPx(35),
              paddingRight: calcularPx(44),
              fontFamily: "Nunito",
            }}
          >
            a gas
          </div>
          <div style={{ height: calcularPx(53), width: "100%" }} />
          <div
            style={{
              height: calcularPx(186),
              width: "80%",
              marginLeft: "auto",
              marginRight: calcularPx(44),
              background: "#FFFFFF",
              borderRadius: calcularPx(20),
            }}
          >
            <div
              style={{
                paddingTop: "3.5%",
                marginLeft: "auto",
                maxWidth: calcularPx(450),
                paddingRight: "6%",
                paddingLeft: "5%",
                textAlign: "right",
                color: "#5A5A5A",
                fontSize: calcularPx(17),
                fontFamily: "Nunito",
              }}
            >
              Equipos tanto para gas natural como para gas envasado, de conexión
              superior, para colocar apoyado o de colgar.
              <br />
              Diseño compacto que ocupa menos volúmen.
            </div>
            <div
              style={{
                background: "#FFB000",
                color: "#FFFFFF",
                fontSize: calcularPx(17),
                fontFamily: "Nunito",
                height: calcularPx(22),
                lineHeight: calcularPx(22),
                width: calcularPx(100),
                marginTop: calcularPx(10),
                marginLeft: "auto",
                borderRadius: calcularPx(20),
                paddingLeft: calcularPx(16),
                paddingRight: calcularPx(16),
                textAlign: "center",
                position: "absolute",
                right: "calc(" + calcularPx(44) + " + 5%)",
              }}
            >
              ver más
            </div>
          </div>
        </div>
        <div
          style={{
            height: "45vw",
            width: "100%",
          }}
        />
      </Link>

      <Link to={"/productos/convectores"}>
        <div
          style={{
            position: "relative",
            marginLeft: "3.44%",
            marginRight: "3.44%",
            background: "#FFFFFF",
            minHeight: calcularPx(382),
            height: "calc(" + calcularPx(275) + " + 25.5vw)",
          }}
        >
          <img
            src={require("../../res/banners/home-convectores.png")}
            style={{
              position: "absolute",
              objectFit: "cover",
              height: "auto",
              maxWidth: "75vw",
              top: "calc(" + calcularPx(272) + " - (25.5vw))",
              left: "23%",
              zIndex: 0,
            }}
          />
          <div style={{ height: calcularPx(18), width: "100%" }} />
          <div
            style={{
              color: "#727272",
              textAlign: "left",
              fontWeight: 700,
              lineHeight: calcularPx(35),
              fontSize: calcularPx(35),
              fontFamily: "Nunito",
            }}
          >
            Convectores
          </div>
          <div
            style={{
              color: "#727272",
              textAlign: "left",
              fontWeight: 400,
              lineHeight: calcularPx(35),
              fontSize: calcularPx(35),
              fontFamily: "Nunito",
            }}
          >
            a gas
          </div>
          <div style={{ height: calcularPx(53), width: "100%" }} />
          <div
            style={{
              height: calcularPx(169),
              width: "85%",
              marginRight: "auto",
              marginLeft: 0,
              background: "#FFB000",
              borderRadius: calcularPx(20),
            }}
          >
            <div
              style={{
                paddingTop: "3%",
                marginRight: "auto",
                maxWidth: calcularPx(450),
                paddingLeft: "5%",
                paddingRight: "10%",
                textAlign: "left",
                color: "#FFFFFF",
                fontSize: calcularPx(17),
                fontFamily: "Nunito",
              }}
            >
              Conocé la linea completa de calefactores multigas Uniqo.
              <br />
              Diseñados para brindar el calor más eficiente a tu hogar.
            </div>
            <div
              style={{
                background: "#FFFFFF",
                color: "#FFB000",
                fontSize: calcularPx(17),
                fontFamily: "Nunito",
                height: calcularPx(22),
                lineHeight: calcularPx(22),
                width: calcularPx(100),
                marginTop: calcularPx(8),
                marginRight: "auto",
                left: "4.5%",
                borderRadius: calcularPx(20),
                paddingLeft: calcularPx(16),
                paddingRight: calcularPx(16),
                textAlign: "center",
                position: "absolute",
              }}
            >
              ver más
            </div>
          </div>
        </div>
      </Link>

      <div style={{ height: calcularPx(64), width: "100%" }} />

      <Link to={"/dondeComprar"}>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: calcularPx(604),
            background: "#FFB000",
            paddingLeft: "7%",
          }}
        >
          <img
            src={require("../../res/banners/home-dondeComprar.png")}
            style={{
              position: "absolute",
              objectFit: "cover",
              top: "calc(" + calcularPx(540) + " - (51vw))",
              left: "30%",
              width: "70vw",
              height: "auto",
              zIndex: 0,
            }}
          />
          <div style={{ height: calcularPx(90), width: "100%" }} />
          <div
            style={{
              color: "#FFFFFF",
              fontSize: calcularPx(50),
              maxWidth: calcularPx(400),
              fontFamily: "Nunito",
            }}
          >
            Dónde comprar?
          </div>
          <div style={{ height: calcularPx(25), width: "100%" }} />
          <div
            style={{
              color: "#404040",
              fontSize: calcularPx(25),
              maxWidth: calcularPx(400),
              fontFamily: "Nunito",
            }}
          >
            Buscá por ubicación geográfica los comercios en donde encontrás los
            productos Uniqo
          </div>
          <div style={{ height: calcularPx(128), width: "100%" }} />
          <div
            style={{
              background: "#404040",
              color: "#FFFFFF",
              fontSize: calcularPx(25),
              fontFamily: "Nunito",
              height: calcularPx(47),
              lineHeight: calcularPx(47),
              width: calcularPx(165),
              borderRadius: calcularPx(20),
              textAlign: "center",
              position: "relative",
              zIndex: 3,
            }}
          >
            Buscar
          </div>
          <div style={{ height: calcularPx(128), width: "100%" }} />
        </div>
      </Link>
      <div style={{ height: calcularPx(32), width: "100%" }} />
    </React.Fragment>
  );
};

export default BannersMobile;
