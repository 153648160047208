import React, { Component } from "react";
import { Link } from "react-router-dom";
import calcularPx from "../calcularPx.js";

const SubmenuProductos = ({ mobile, ocultarMenu }) => {
  const style = {
    position: "relative",
    display: "block",
    padding: mobile
      ? calcularPx(30) + " " + calcularPx(15)
      : calcularPx(10) + " " + calcularPx(10),
    marginTop: mobile ? "" : calcularPx(10),
    marginBottom: mobile ? "" : calcularPx(5),
    textDecoration: "none",
    fontFamily: "Nunito",
    fontSize: calcularPx(16),
    fontWeight: 700,
    color: mobile ? "#727272" : "",
  };

  return (
    <nav
      style={{
        position: "relative",
        left: mobile ? "" : "-" + calcularPx(20),
        top: calcularPx(mobile ? 19.5 : 36.5),
        width: calcularPx(175),
        background: mobile ? "#ffffff" : "#f3f3f3",
        padding: mobile ? "" : calcularPx(7) + " " + calcularPx(10),
        borderBottomLeftRadius: calcularPx(25),
        borderBottomRightRadius: calcularPx(25),
      }}
    >
      <Link
        to="/productos/convectores"
        onClick={ocultarMenu}
        style={style}
        className={mobile ? "" : "submenuProductos"}
      >
        Convectores
      </Link>
      <Link
        to="/productos/termotanquesGas"
        onClick={ocultarMenu}
        style={style}
        className={mobile ? "" : "submenuProductos"}
      >
        Termotanques
      </Link>
    </nav>
  );
};

export default SubmenuProductos;
