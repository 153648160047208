import React, { Component } from "react";
import { Link } from "react-router-dom";
import calcularPx from "../calcularPx.js";
import { ReactComponent as IcoChevron } from "../../../res/iconos/chevron.svg";
import SubmenuProductos from "./submenuProductos.jsx";
import { createRef } from "react";
import { CSSTransition } from "react-transition-group";

class Menu extends Component {
  state = { mostrarSubmenu: false };

  //constructor, componentDidMount, componentWillUnmount y handleClickOutside son para ocultar el submenu de productos tocando fuera de el
  constructor(props) {
    super(props);
    this.ref = createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  handleClickOutside(event) {
    if (this.ref.current && !this.ref.current.contains(event.target))
      this.setState({ mostrarSubmenu: false });
  }

  style(left) {
    let style = {
      background: "transparent",
      border: "none",
    };
    if (this.props.mobile) {
      style.color = "#727272";
      style.position = "relative";
      style.display = "block";
      style.width = "100%";
      style.padding = calcularPx(30) + " " + calcularPx(0);
      style.textAlign = "left";
    } else {
      style.position = "absolute";
      style.left = left;
      style.top = calcularPx(40);
      style.minWidth = calcularPx(228.13);
      style.textAlign = "left";
    }
    return style;
  }

  render() {
    const { mobile, ocultar } = this.props;
    return (
      <nav
        style={{
          background: "#FFFFFF",
          paddingLeft: mobile ? "3.44%" : 0,
          height: mobile ? "100vh" : "auto",
          marginTop: 35,
        }}
      >
        <div ref={this.ref}>
          <button
            className={"itemNavBar"}
            style={this.style("45.07%")}
            onClick={() => {
              this.setState({ mostrarSubmenu: !this.state.mostrarSubmenu });
            }}
            onMouseEnter={
              mobile
                ? null
                : () => {
                    this.setState({ mostrarSubmenu: true });
                  }
            }
            onMouseLeave={
              mobile
                ? null
                : () => {
                    this.setState({ mostrarSubmenu: false });
                  }
            }
          >
            PRODUCTOS
            <CSSTransition
              in={this.state.mostrarSubmenu}
              timeout={250}
              classNames="girar"
            >
              <IcoChevron
                width={calcularPx(15)}
                height={calcularPx(15)}
                style={{
                  position: mobile ? "absolute" : "static",
                  marginLeft: mobile ? "" : "5%",
                  left: "90%",
                }}
                stroke={mobile ? "#727272" : "#FFFFFF"}
              />
            </CSSTransition>
            <CSSTransition
              in={this.state.mostrarSubmenu}
              timeout={250}
              classNames="submenu"
              unmountOnExit
            >
              <SubmenuProductos mobile={mobile} ocultarMenu={ocultar} />
            </CSSTransition>
          </button>
        </div>

        <Link
          className={"itemNavBar"}
          onClick={ocultar}
          to="/dondeComprar"
          style={this.style("56.74%")}
        >
          DÓNDE COMPRAR
        </Link>
        <Link
          className={"itemNavBar"}
          onClick={ocultar}
          to="/posventa"
          style={this.style("70.14%")}
        >
          POSVENTA
        </Link>
        <Link
          className={"itemNavBar"}
          onClick={ocultar}
          to="/preguntasFrecuentes"
          style={this.style("80.14%")}
        >
          PREGUNTAS FRECUENTES
        </Link>
      </nav>
    );
  }
}

export default Menu;
