import React, { Component } from "react";
import calcularPx from "../general/calcularPx.js";
import { ReactComponent as IcoMenos } from "../../res/iconos/menos.svg";
import { ReactComponent as IcoMas } from "../../res/iconos/mas.svg";
import { CSSTransition } from "react-transition-group";

class Pregunta extends Component {
  state = { mostrarRespuesta: false };
  render() {
    const { pregunta, respuesta } = this.props;
    return (
      <div
        style={{
          maxWidth: calcularPx(692),
          marginLeft: "6.2%",
          paddingTop: calcularPx(10),
          paddingBottom: calcularPx(10),
        }}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#F1F1F1",
            paddingTop: calcularPx(20),
            paddingBottom: calcularPx(20),
            borderRadius: 5,
          }}
        >
          <button
            style={{
              display: "flex",
              width: "100%",
              background: "transparent",
              border: "none",
              paddingLeft: calcularPx(12),
              paddingRight: calcularPx(12),
            }}
            onClick={() => {
              this.setState({ mostrarRespuesta: !this.state.mostrarRespuesta });
            }}
          >
            <h1
              className="pregunta"
              style={{
                flex: 1,
                minWidth: "80%",
                textAlign: "left",
                margin: 0,
              }}
            >
              {pregunta}
            </h1>
            {this.state.mostrarRespuesta ? (
              <IcoMenos
                style={{
                  flex: 2,
                  alignSelf: "center",
                  paddingLeft: "15%",
                }}
                width={calcularPx(12)}
                height={calcularPx(12)}
              />
            ) : (
              <IcoMas
                style={{
                  flex: 2,
                  alignSelf: "center",
                  paddingLeft: "15%",
                }}
                width={calcularPx(12)}
                height={calcularPx(12)}
              />
            )}
          </button>
          <CSSTransition
            in={this.state.mostrarRespuesta}
            timeout={100}
            classNames="opacidad"
            unmountOnExit
          >
            <div
              className="respuesta"
              style={{
                height: "auto",
                paddingTop: calcularPx(10),
                paddingLeft: calcularPx(12),
                paddingRight: calcularPx(12),
                textAlign: "justify",
              }}
            >
              {respuesta}
            </div>
          </CSSTransition>
        </div>
      </div>
    );
  }
}

export default Pregunta;
