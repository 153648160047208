import React, { Component } from "react";
import calcularPx from "../general/calcularPx.js";
import Pregunta from "./pregunta.jsx";

class PreguntasFrecuentes extends Component {
  state = { preguntas: [] };

  componentDidMount() {
    window.scrollTo(0, 0);
    fetch("/api/preguntasFrecuentes")
      .then((res) => res.json())
      .then((data) => {
        this.setState({ preguntas: data });
      });
  }

  render() {
    const mobileBanner = window.innerWidth < 834;
    const mobile = window.innerWidth < 1130;
    return (
      <React.Fragment>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "auto",
            textJustify: "left",
            left: 0,
            top: 0,
          }}
        >
          <img
            src={require(mobileBanner
              ? "../../res/banners/preguntasFrecuentesMobile.jpg"
              : "../../res/banners/preguntasFrecuentesDesktop.jpg")}
            style={{
              position: "relative",
              objectFit: "cover",
              objectPosition: "right",
              width: "100%",
              height: "auto",
              left: 0,
              top: 0,
              zIndex: -1,
            }}
          />
          <img
            src={require("../../res/banners/preguntasFrecuentesTexto.png")}
            style={{
              position: "absolute",
              objectFit: "cover",
              objectPosition: "right",
              top: 0,
              bottom: 0,
              marginTop: "auto",
              marginBottom: "auto",
              maxHeight: "60%",
              maxWidth: mobileBanner ? "40%" : "",
              right: "5%",
              zIndex: -1,
            }}
          />
        </div>

        <div style={{ width: "100%", height: calcularPx(64) }} />

        <div style={{ width: "100%", display: "flex" }}>
          <div
            style={{
              flex: 7,
              maxWidth: "90%",
            }}
          >
            <div
              style={{
                width: "100%",
                height: calcularPx(50),
                background: "#FFB000",
                borderBottomRightRadius: 25,
                borderTopRightRadius: 25,
                color: "#FFFFFF",
                fontSize: calcularPx(18),
                fontWeight: 800,
                fontFamily: "Nunito",
                lineHeight: calcularPx(50),
                paddingLeft: "6.2%",
              }}
            >
              Termotanques Uniqo
            </div>

            <div style={{ width: "100%", height: calcularPx(52) }} />

            {this.state.preguntas
              .filter((p) => p.cat.toUpperCase().includes("TERMOTANQUES"))
              .map((e) => (
                <Pregunta pregunta={e.pregunta} respuesta={e.respuesta} />
              ))}
          </div>
          {!mobile && (
            <div
              style={{
                position: "relative",
                flex: 3,
                marginLeft: calcularPx(32),
                height: "auto",
                background: "#FFB000",
                borderTopLeftRadius: 25,
                borderBottomLeftRadius: 25,
                zIndex: -2,
              }}
            >
              <img
                src={require("../../res/banners/faq-termotanques.png")}
                style={{
                  position: "absolute",
                  right: "40%",
                  bottom: "0%",
                  objectFit: "cover",
                  maxWidth: calcularPx(573),
                  height: "auto",
                  zIndex: -1,
                }}
              />
            </div>
          )}
        </div>

        <div style={{ width: "100%", height: calcularPx(81) }} />

        <div style={{ width: "100%", display: "flex" }}>
          <div
            style={{
              flex: 7,
              maxWidth: "90%",
            }}
          >
            <div
              style={{
                width: "100%",
                height: calcularPx(50),
                background: "#FFB000",
                borderBottomRightRadius: 25,
                borderTopRightRadius: 25,
                color: "#FFFFFF",
                fontSize: calcularPx(18),
                fontWeight: 800,
                fontFamily: "Nunito",
                lineHeight: calcularPx(50),
                paddingLeft: "6.2%",
              }}
            >
              Convectores Uniqo
            </div>

            <div style={{ width: "100%", height: calcularPx(52) }} />

            {this.state.preguntas
              .filter((p) => p.cat.toUpperCase().includes("CONVECTORES"))
              .map((e) => (
                <Pregunta pregunta={e.pregunta} respuesta={e.respuesta} />
              ))}
          </div>
          {!mobile && (
            <div
              style={{
                position: "relative",
                flex: 3,
                marginLeft: calcularPx(32),
                height: "auto",
                background: "#FFB000",
                borderTopLeftRadius: 25,
                borderBottomLeftRadius: 25,
                zIndex: -2,
              }}
            >
              <img
                src={require("../../res/banners/faq-convectores.png")}
                style={{
                  position: "absolute",
                  right: "40%",
                  bottom: "0%",
                  objectFit: "cover",
                  maxWidth: calcularPx(573),
                  height: "auto",
                  zIndex: -1,
                }}
              />
            </div>
          )}
        </div>

        <div style={{ width: "100%", height: calcularPx(100) }} />
      </React.Fragment>
    );
  }
}

export default PreguntasFrecuentes;
